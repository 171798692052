@import "../../variables";

.header {
  background: $white;
  padding: 20px 0;
  position: sticky;
  top: 0;
  z-index: 100;
  box-shadow: 0px 0px 5px 0px $text;

  &__nav {
    @include flex;
    justify-content: space-between;

    &-logo {
      @include flex;
      gap: 11px;
    }

    &-link {
      width: 80px;
      height: 80px;
      @include flexCenter;
    }

    &-icon {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 5px;
      box-shadow: 0px 0px 7px 0px $text;
    }

    &-text {
      @include flexCol;
      gap: 5px;
      border-left: 1px solid $text;
      padding-left: 11px;

      p {
        font-weight: 600;
        color: $text;

        span {
          color: $red;
        }
      }
    }

    &-right {
      @include flex;
      gap: 33px;
      @include transition;
      
      @media screen and (max-width: 957px) {
        position: absolute;
        top: 120px;
        left: -120%;
        background: $white;
        width: 100%;
        height: 100vh;
        // @include flexColCenter;
        @include flexCol;
        gap: 24px;
        //
        justify-content: flex-start;
        padding-top: 222px;
        
        // &:not(:last-child) {
          //   border-bottom: 1px solid $advancedBack;
          // }
          
          &-active {
            position: absolute;
            left: 0%;            
        }
      }
    }
  }

  // burger
  &__burger {
    display: none;
    cursor: pointer;
    @include transition;

    @media screen and (max-width: 957px) {
      display: flex;
      flex-direction: column;
    }

    i {
      background-color: black;
      width: 25.6px;
      height: 2px;
      margin: 4px;
      border-radius: 2px;
      -webkit-border-radius: 2px;
      -moz-border-radius: 2px;
      -ms-border-radius: 2px;
      -o-border-radius: 2px;
    }

    .open,
    .close {
      @include transition;
    }

    .open:nth-child(1) {
      transform: rotate(45deg) translateY(14px);
      -webkit-transform: rotate(45deg) translateY(14px);
      -moz-transform: rotate(45deg) translateY(14px);
      -ms-transform: rotate(45deg) translateY(14px);
      -o-transform: rotate(45deg) translateY(14px);
    }

    .open:nth-child(2) {
      opacity: 0;
      transition: all 0.1s ease;
      -webkit-transition: all 0.1s ease;
      -moz-transition: all 0.1s ease;
      -ms-transition: all 0.1s ease;
      -o-transition: all 0.1s ease;
    }

    .open:nth-child(3) {
      transform: rotate(-45deg) translateY(-14px);
      -webkit-transform: rotate(-45deg) translateY(-14px);
      -moz-transform: rotate(-45deg) translateY(-14px);
      -ms-transform: rotate(-45deg) translateY(-14px);
      -o-transform: rotate(-45deg) translateY(-14px);
    }

    .close:nth-child(1) {
      transform: rotate(0) translateY(0);
      -webkit-transform: rotate(0) translateY(0);
      -moz-transform: rotate(0) translateY(0);
      -ms-transform: rotate(0) translateY(0);
      -o-transform: rotate(0) translateY(0);
    }

    .close:nth-child(2) {
      opacity: 1;
    }

    .close:nth-child(3) {
      transform: rotate(0) translateY(0);
      -webkit-transform: rotate(0) translateY(0);
      -moz-transform: rotate(0) translateY(0);
      -ms-transform: rotate(0) translateY(0);
      -o-transform: rotate(0) translateY(0);
    }
  }
}