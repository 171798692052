@import "../../variables";

$skeleton: $bg;
$animaiton: skeleton 0.5s ease infinite alternate;

@keyframes skeleton {
  to {
    opacity: 0.5;
  }
}

.skeleton {
  overflow-x: auto;
  width: 100%;

  &::-webkit-scrollbar {
    width: 0px !important;
    height: 0px !important;
  }

  &__header {
    min-width: 100px;
    height: 28px;
    background: $skeleton;
    animation: $animaiton;

    // @media screen and (max-width: 1280px) {
    //   min-width: 100px;
    //   height: 28px;
    // }
  }

  &__cards {
    display: flex;
    justify-content: space-between;
    gap: 25px;
    padding: 100px 5px;
    overflow-x: auto;
    width: 100%;
  
    &::-webkit-scrollbar {
      width: 0px !important;
      height: 0px !important;
    }

    &-card {
      min-width: 373px;
      height: 232px;
      // min-width: 253px;
      // height: 404px;
      border-radius: 5px;
      background: $skeleton;
      animation: $animaiton;
    }

    // @media screen and (max-width: 1280px) {
    //   min-width: 100px;
    //   height: 28px;
    // }
  }
}
