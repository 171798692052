@import "../../variables";

.scrollTop {
  display: none;

  &-visible {
    @include transition;
    display: block;
    position: fixed;
    right: 55px;
    bottom: 111px;
    z-index: 101;
    cursor: pointer;
    font-size: 44px;
    // color: $green;
  }
}
