@import "../../../../variables";

.language {
  @include flexCol;
  position: relative;

  &__choose {
    @include flexCenter;
    background: $white;
    cursor: pointer;
  }

  button {
    background: transparent;
    color: $text;
    cursor: pointer;
    @include transition;
  }

  &__dropdown {
    display: none;
  }

  &__dropdown-active {
    position: absolute;
    top: 33px;
    @include flexCol;
    gap: 5px;
    background: $white;

    li {
      cursor: pointer;
    }
  }
}
