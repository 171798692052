@import "../../variables";

.footer {
  background: $red;
  box-shadow: 0px 0px 5px 1px $text;

  &__top {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    color: $white;
    padding: 50px 0;

    &-right {
      display: flex;
      gap: 30px;
      padding-right: 30px;
      flex-wrap: wrap;


      @media screen and (max-width: 768px) {
        justify-content: center;
      }

      &-logo {
        width: 80px;
        height: 80px;
        object-fit: cover;
        border-radius: 5px;
        box-shadow: 0px 0px 7px 4px $white;
        // box-shadow: 0px 0px 11px 0px $white;
        
        @media screen and (max-width: 768px) {
          width: 60px;
          height: 60px;
        }
      }

      &-social {
        @include flexCol;
        // @include flex;
        gap: 33px;


        &-link {
          @include flexCenter;
          font-size: 25px;
          color: $white;
          border: 3px solid $white;
          padding: 11px;
          width: fit-content;
          border-radius: 100%;
          @include transition;


          @media screen and (max-width: 768px) {
            font-size: 16px;
          }

          &:hover {
            background: $white;
            color: $red;
            border: 3px solid $red;
            box-shadow: 0px 0px 11px 0px $white;
          }
        }
      }
    }

    &-left {
      display: flex;
      gap: 30px;

      @media screen and (max-width: 768px) {
        flex-wrap: wrap;
      }

      h3 {
        width: fit-content;
        font-size: 20px;
        letter-spacing: 0.5px;
        border-bottom: 1px solid $white;
        padding-bottom: 5px;
      }

      &-card {
        @include flexCol;
        gap: 33px;

        &-list {
          @include flexCol;
          gap: 11px;
        }

        &-link {
          color: $white;
          letter-spacing: 0.5px;
          font-size: 16px;
          text-transform: capitalize;
          border-bottom: 1px solid transparent;
          @include transition;

          &:hover,
          &focus {
            border-bottom: 1px solid $white;
          }
        }
      }
    }
  }

  &__bottom {
    padding: 20px 0;
    background: $white;

    
    &-main {
      @include flex;
      justify-content: space-between;
      
      @media screen and (max-width: 768px) {
        flex-wrap: wrap;
        justify-content: center;
        gap: 11px;
      }

      p {
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0.5px;
        color: $text;

        @media screen and (max-width: 768px) {
          text-align: center;
        }

        a {
          font-weight: 600;
          color: $red;
        }
      }
    }
  }
}