@import "./variables.scss";

*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  list-style: none;
  border: none;
  outline: none;
  scroll-behavior: smooth;
}

html,
body {
  width: 100%;
  height: 100%;
  font-family: "Noto Sans Armenian";
  font-style: normal;
  @include transition;

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background-color: $advancedBack;
  }

  ::-webkit-scrollbar-thumb {
    background-color: $red;
    border-radius: 8px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: darken($red, 3%);
  }
}

input,
button,
textarea {
  font-family: "Noto Sans Armenian";
  font-style: normal;
  cursor: pointer;
}

.container {
  margin: 0 auto;
  max-width: 1440px;
  padding: 0 120px;

  @media screen and (max-width: 1280px) {
    padding: 0 30px;
  }

  @media screen and (max-width: 768px) {
    padding: 0 15px;
  }
}

.loader {
  width: 100%;
  background: $white;
  z-index: 99;
  @include flexCenter;
  margin: 0 auto;
  height: 100vh;

  img {
    width: 252px;
    height: 252px;
  }

  @media screen and (max-width: 768px) {
    width: 151px;
    height: 151px;
  }
}

img {
  object-fit: cover;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

// all pages
section {
  padding: 100px 0;

  @media screen and (max-width: 1280px) {
    padding: 50px 0;
  }

  // h2 {
  //   // text-transform: capitalize;
  //   font-size: 40px;
  //   letter-spacing: 0.5px;
  //   border-bottom: 2px solid $red;
  //   width: fit-content;
  // }
}
