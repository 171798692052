@import "../../../../variables";

.routes {
  @include flex;
  gap: 33px;

  @media screen and (max-width: 957px) {
    flex-direction: column;
    position: relative;
  }

  &__link {
    @include flex;
    color: $text;
    background: transparent;
    font-size: 19px;
    letter-spacing: 0.5px;
    // border-radius: 4px;
    // padding: 7px;
    position: relative;
    border-bottom: 2px solid transparent;
    @include transition;
    text-transform: capitalize;

    &:hover {
      color: $red;
    }

    &-down {
      color: $text;
      font-size: 33px;
    }
  }

  .active {
    // background: $white;
    border-bottom: 2px solid $red;
  }

  &__dropdown {
    display: none;
  }

  &__dropdownOpen {
    background: $white;
    // border: 1px solid $red;
    border-radius: 4px;
    display: block;
    position: absolute;
    top: 90px;
    margin-left: -15px;
    padding: 15px;
    @include flexCol;
    gap: 22px;
    //
    
    @media screen and (max-width: 957px) {
      // box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.25);
      z-index: 2;
      top: 222px;
    }
  }
}